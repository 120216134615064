import Axios from 'axios';
import { User } from './userApi';
import base64ImageToBlob from 'util/base64ImageToBlob';
import { Deletable } from 'components/common/ImageSelectGallery';
import { MoistureMeter } from './moistureMeterApi';
import { SelectedImages } from 'components/PhotoReports/PhotoReportDownloadDialog';

export interface InspectionTechnician {
  userId: string;
  organizationId: string;
  jobId: string;
  inspectionId: string;
  user: User;
}
export enum AsbestosOnSite {
  YES = 'YES',
  NO = 'NO',
  POSSIBLE = 'POSSIBLE',
}

export enum MouldOnSite {
  YES = 'YES',
  NO = 'NO',
  POSSIBLE = 'POSSIBLE',
}

export interface Inspection {
  id: string;
  jobId: string;
  createdByUser?: User;
  createdByUserId: string;
  inspectionDate: string;
  isInitialAttendance?: boolean;
  causeOfDamage?: string;
  timeOfDamage?: string;
  damageIsRectified?: boolean;
  rectifiedSelection?: number;
  isMouldOnSite?: MouldOnSite;
  mouldSelection?: MouldOnSite;
  isAsbestosOnSite?: AsbestosOnSite;
  asbestosSelection?: AsbestosOnSite;
  waterCategory?: string;
  waterClass?: string;
  propertyAge?: number;
  rhOutside?: number;
  rhInside?: number;
  damageDescription: string;
  lastUpdatedOn: string;
  updatedByUser?: User;
  temperatureUnits?: TemperatureUnits;
  temperatureOutside?: number;
  temperatureInside?: number;
  roofType?: string;
  foundationType?: FoundationTypes;
  exteriorCladdingType?: CladdingTypes;
  typeOfDwelling?: DwellingTypes;
  conditionOfProperty?: PropertyConditions;
  temporaryAccommodationRequired?: boolean;
  temporaryAccommodationRequiredSelection?: number;
  whyAccommodationRequired?: string;
}

export enum MoistureReadingLocation {
  WALLS = 'Walls',
  SUBFLOOR = 'Subfloor',
  STRUCTURE = 'Structural Framework',
  FLOOR_COVERING = 'Floor Covering',
  CEILING = 'Ceiling',
}

export enum MoistureReadingMethod {
  SCAN = 'Scan',
  PENETRATIVE = 'Penetrative',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum MoistureReadingType {
  CONTROL_READING = 'CONTROL_READING',
  MOISTURE_READING = 'MOISTURE_READING',
}

export interface locationReadingImage {
  id?: string;
  locationReadingId: string;
  moistureReadingId: string;
  organizationId: number;
  jobId: string;
  gcsPath: string;
}

export interface LocationReadings {
  location: string;
  reading: string;
  material: string;
  id?: string;
  moistureReadingId: string;
  photos?: locationReadingImage[];
  isValidReading?: boolean;
}

export interface MoistureReading {
  id?: string;
  location: MoistureReadingLocation;
  locationReadings: LocationReadings[];
  meter: string;
  method: MoistureReadingMethod;
  jobId?: string;
  roomId?: string;
  inspectionId?: string;
  moistureMeterId?: string;
  moistureMeter?: MoistureMeter;
  isLegacyReading?: boolean;
  type: MoistureReadingType;
}

export interface MoistureReadingForm extends MoistureReading {
  moistureReadingsMap: Record<string, Partial<MoistureReading>>;
  controlReadingsMap?: Record<string, Partial<MoistureReading>>;
}

export interface ControlReadings {
  location: MoistureReadingLocation;
  reading: string;
  meter: string;
  method: MoistureReadingMethod;
  material: string;
}

export enum LengthUnit {
  mm = 'mm',
  cm = 'cm',
  m = 'm',
  feet = 'feet',
  inches = 'inches',
}

export enum PropertyConditions {
  POOR = 'POOR',
  FAIR = 'FAIR',
  AVERAGE = 'AVERAGE',
  GOOD = 'GOOD',
  EXCELLENT = 'EXCELLENT',
}

export enum FoundationTypes {
  BEARERS_AND_JOISTS = 'BEARERS_AND_JOISTS',
  SUSPENDED_CONCRETE_SLAB = 'SUSPENDED_CONCRETE_SLAB',
  TIMBER_POST = 'TIMBER_POST',
  STEEL_POST = 'STEEL_POST',
  CONCRETE_PIER = 'CONCRETE_PIER',
  SCREW_PIER_OR_BOARD_PIER = 'SCREW_PIER_OR_BOARD_PIER',
  OTHER = 'OTHER',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum DwellingTypes {
  SINGLE_STORY = 'SINGLE_STORY',
  DOUBLE_STORY = 'DOUBLE_STORY',
  SPLIT_LEVEL = 'SPLIT_LEVEL',
  MULTI_STORY = 'MULTI_STORY',
}
export enum CladdingTypes {
  TIMBER = 'TIMBER',
  ASBESTOS = 'ASBESTOS',
  FC_CLADDING = 'FC_CLADDING',
  VINYL_CLADDING = 'VINYL_CLADDING',
  DOUBLE_BRICK = 'DOUBLE_BRICK',
  RENDER = 'RENDER',
  WEATHER_BOARD = 'WEATHER_BOARD',
  MASONRY_BLOCK = 'MASONRY_BLOCK',
  METAL_CLADDING = 'METAL_CLADDING',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export interface Room {
  displayName: string;
  id: string;
  jobId: string;
  roomName: string;
  description?: string;
  createdByUser?: User;
  createdByUserId: string;
  createdOn: string;
  updatedOn: string;
  updatedByUser?: User;
  updatedByUserId: string;
  floorCoverType: FloorCoverType;
  underlay: string;
  subfloor: SubfloorType;
  floorCoveringRemovedPortion: number;
  affectedPortion: number;
  salvageable: boolean;
  moistureReadings: MoistureReading[];
  height: number;
  width: number;
  length: number;
  measurementUnits: LengthUnit;
  mouldIsPresent: boolean;
  mouldLocation: string;
  furnitureToBeMoved: boolean;
  observations?: string;
}

export interface RoomDetailsForm extends Room {
  salvageableSelection: number;
  mouldPresenceSelection: number;
  furnitureToBeMovedSelection: number;
  // moistureReadingsMap: Record<string, Partial<MoistureReading>>;
}

export interface RoomFeedItem {
  id?: string;
  jobId: string;
  inspectionId?: string;
  roomId: string;
  roomNote?: RoomNote;
  inventoryItem?: InventoryItem;
  photoGroup?: RoomPhotoGroup;
  photos?: RoomPhoto[];
  updatedOn?: string;
  updatedByUser?: User;
  type?: RoomFeedItemType;
}

export enum RoomFeedItemType {
  PHOTO_NOTE = 'PHOTO_NOTE',
  INVENTORY_NOTE = 'INVENTORY_NOTE',
  EQUIPMENT_NOTE = 'EQUIPMENT_NOTE',
  ROOM_DETAILS = 'ROOM_DETAILS',
  MOISTURE = 'MOISTURE',
}

export const feedTypeEnumToString = (category: string) => {
  switch (category) {
    case RoomFeedItemType.PHOTO_NOTE:
      return 'Photo Notes';
    case RoomFeedItemType.EQUIPMENT_NOTE:
      return 'Equipment';
    case RoomFeedItemType.INVENTORY_NOTE:
      return 'Inventory';
    case RoomFeedItemType.ROOM_DETAILS:
      return 'Room Details';
    case RoomFeedItemType.MOISTURE:
      return 'Moisture';
  }
};

export enum InventoryItemDisposition {
  RESTORABLE = 'RESTORABLE',
  NON_RESTORABLE = 'NON_RESTORABLE',
  UNAFFECTED = 'UNAFFECTED',
  E_TEST_REQUIRED = 'E_TEST_REQUIRED',
  SPECIALIST_REQUIRED = 'SPECIALIST_REQUIRED',
}

export enum FloorCoverType {
  WOOL = 'WOOL',
  NYLON = 'NYLON',
  POLYPROPYLENE = 'POLYPROPYLENE',
  WOOL_CARPET_TILE = 'WOOL_CARPET_TILE',
  NYLON_CARPET_TILES = 'NYLON_CARPET_TILES',
  ENGINEERED_TIMBER = 'ENGINEERED_TIMBER',
  LAMINATE_TIMBER = 'LAMINATE_TIMBER',
  CONCRETE_TILES = 'CONCRETE_TILES',
  CERAMIC_TILES = ' CERAMIC_TILES ',
  TERRACOTTA_TILES = 'TERRACOTTA_TILES',
  CORK_FLOORING = ' CORK_FLOORING',
  POLISHED_CONCRETE = ' POLISHED_CONCRETE',
  OTHER = 'OTHER',
  WOOL_CARPET = 'WOOL_CARPET',
  NYLON_CARPET = 'NYLON_CARPET',
  POLYPROPYLENE_CARPET = 'POLYPROPYLENE_CARPET',
}

export enum SubfloorType {
  CONCRETE = 'CONCRETE',
  TIMBER = 'TIMBER',
  PARTICLE_BOARD = 'PARTICLE_BOARD',
  MIXED = 'MIXED',
}

export enum WaterCategory {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  CAT_1 = 'CAT_1',
  CAT_2 = 'CAT_2',
  CAT_3 = 'CAT_3',
}

export enum WaterClass {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  CLASS_1 = 'CLASS_1',
  CLASS_2 = 'CLASS_2',
  CLASS_3 = 'CLASS_3',
  CLASS_4 = 'CLASS_4',
}

export enum TemperatureUnits {
  Celsius = 'Celsius (°C)',
  Fahrenheit = 'Fahrenheit (°F)',
  Kelvin = 'Kelvin (K)',
}

export interface InventoryItem {
  id: string;
  itemName: string;
  disposition: string;
  modelNumber?: string;
  serialNumber?: string;
  brand?: string;
  purchasePrice?: number;
  ageYears?: number;
  ageMonths?: number;
  notes: string;
  height?: number;
  width?: number;
  length?: number;
  measurementUnits?: LengthUnit;
}

// export interface InventoryPhoto {
//     id: string;
//     jobId: string;
//     roomId: string;
//     updatedOn: string;
//     updatedByUser?: User;
//     gcsPath?: string;
//     photo?: any;
//     base64String?: string;
// }
export interface RoomNote {
  id?: number;
  jobId: string;
  roomId: string;
  inspectionId?: string;
  roomFeedItemId?: string;
  title?: string;
  content?: string;
  caption?: string;
  updatedOn?: string;
  updatedByUser?: User;
  isDefault?: boolean;
}

export interface RoomPhotoGroup {
  id: number;
  jobId: string;
  roomId: string;
  updatedOn: string;
  updatedByUser?: User;
}

export interface RoomPhoto {
  id: string;
  jobId: string;
  roomId: string;
  updatedOn: string;
  updatedByUser?: User;
  gcsPath?: string;
  photo?: any;
  base64String?: string;
  isModelPhoto?: boolean;
}

export interface UploadPhoto {
  isDefault: boolean;
  id?: string;
  gcsPath: string;
  isModelPhoto?: boolean;
}

export interface RotatedPhotoDetailsInterface {
  id: string;
  rotatedDegree: number;
}

export async function listInspections(jobId: string): Promise<Inspection[]> {
  const res = await Axios.get(`/api/v1/inspections/${jobId}`);
  return res.data;
}

export async function getInspectionById(
  jobId: string,
  inspectionId: string
): Promise<Inspection> {
  const res = await Axios.get(`/api/v1/inspections/${jobId}/${inspectionId}`);
  return res.data;
}

export async function getInitialInspection(jobId: string): Promise<Inspection> {
  const searchParams = new URLSearchParams(
    `isInitialAttendance=${true}`
  ).toString();

  const res = await Axios.get(`/api/v1/inspections/${jobId}?${searchParams}`);
  return res.data;
}

export async function addInspection({
  jobId,
  inspectionDate,
  isInitialAttendance,
  causeOfDamage,
  damageIsRectified,
  isAsbestosOnSite,
  isMouldOnSite,
  propertyAge,
  rectifiedSelection,
  rhInside,
  rhOutside,
  waterCategory,
  waterClass,
  timeOfDamage,
  damageDescription,
  temperatureUnits,
  temperatureOutside,
  temperatureInside,
  id,
  roofType,
  foundationType,
  exteriorCladdingType,
  typeOfDwelling,
  conditionOfProperty,
  temporaryAccommodationRequired,
  whyAccommodationRequired,
}: Partial<Inspection>) {
  const res = await Axios.post(`/api/v2/inspections/${jobId}`, {
    inspectionDate: inspectionDate ?? new Date(), //some times this is undefined
    isInitialAttendance,
    causeOfDamage,
    damageIsRectified,
    isAsbestosOnSite,
    isMouldOnSite,
    propertyAge,
    rectifiedSelection,
    rhInside: Number(rhInside) ?? 0,
    rhOutside: Number(rhOutside) ?? 0,
    waterCategory,
    waterClass,
    timeOfDamage,
    damageDescription,
    temperatureUnits,
    temperatureOutside: Number(temperatureOutside) ?? 0,
    temperatureInside: Number(temperatureInside) ?? 0,
    id,
    roofType,
    foundationType,
    exteriorCladdingType,
    typeOfDwelling,
    conditionOfProperty,
    temporaryAccommodationRequired,
    whyAccommodationRequired,
  });

  return res.data;
}

export async function updateInspection(data: Partial<Inspection>) {
  const { id, jobId, ...rest } = data;
  const res = await Axios.put(`/api/v2/inspections/${jobId}/${id}`, rest);
  return res.data;
}

export async function deleteInspection(
  inspection: Pick<Inspection, 'jobId' | 'id'>
) {
  const { id, jobId } = inspection;

  const res = await Axios.delete(`/api/v1/inspections/${jobId}/${id}`);
  return res.data;
}

export async function listRooms(jobId: string): Promise<Room[]> {
  const res = await Axios.get(`/api/v1/rooms/${jobId}`);
  return res.data;
}

export async function addRoom({
  id,
  jobId,
  roomName,
}: Pick<Room, 'id' | 'jobId' | 'roomName'>) {
  const res = await Axios.post(`/api/v1/rooms/${jobId}`, {
    id,
    roomName,
  });
  return res.data;
}

export async function updateRoom(room: Partial<Room>) {
  const { id, jobId, ...rest } = room;
  const res = await Axios.put(`/api/v1/rooms/${jobId}/${id}`, rest);
  return res.data;
}

export async function deleteRoom(room: Pick<Room, 'jobId' | 'id'>) {
  const { id, jobId } = room;
  const res = await Axios.delete(`/api/v1/rooms/${jobId}/${id}`);
  return res.data;
}

export async function listRoomFeedItemIds(
  jobId: string,
  type: RoomFeedItemType
): Promise<string[]> {
  const res = await Axios.get(`/api/v1/jobs/${jobId}/roomFeedItemIds/${type}`);
  return res.data;
}

export async function listRoomFeedItems(
  jobId: string,
  roomId: string,
  inspectionId: string,
  type?: RoomFeedItemType
): Promise<RoomFeedItem[]> {
  const res = await Axios.get(
    `/api/v1/rooms/${jobId}/${roomId}/${inspectionId}/${type}/roomFeedItems`
  );
  return res.data;
}

// this is related to reports and download
export async function getRoomFeedItemsByJob(
  jobId: string,
  type?: RoomFeedItemType
): Promise<SelectedImages[]> {
  const res = await Axios.get(`/api/v1/rooms/${jobId}/roomFeedItem/${type}`);
  return res.data;
}

export async function deleteRoomFeedItem(roomFeedItem: RoomFeedItem) {
  const res = await Axios.delete(
    `/api/v1/rooms/${roomFeedItem.jobId}/${roomFeedItem.roomId}/${roomFeedItem.inspectionId}/${roomFeedItem.id}`
  );
  return res.data;
}

//upload photo note (photo and note)
export async function uploadPhotoNote({
  id,
  room,
  inspectionId,
  photos,
  onUploadProgress,
  note,
}: {
  id?: string;
  room: Pick<Room, 'id' | 'jobId'>;
  inspectionId: string;
  photos: UploadPhoto[]; // base64 encoded from react-webcam
  onUploadProgress: (e: ProgressEvent) => void;
  note?: Pick<RoomNote, 'content' | 'caption'>;
}) {
  const formData = new FormData();
  photos.forEach((photo: UploadPhoto) => {
    formData.append(`photos`, base64ImageToBlob(photo.gcsPath));
    if (photo.id) {
      formData.append(`photoIds`, photo.id);
    }
  });

  formData.append('id', id ?? '');
  formData.append('note', note?.content ?? '');
  formData.append('caption', note?.caption ?? '');
  const res = await Axios.post(
    `/api/v1/rooms/${room.jobId}/${room.id}/${inspectionId}/room-feed-item-photos`,
    formData,
    { onUploadProgress }
  );

  return res.data;
}

//update photo note (photo and note)
export async function updatePhotoNote({
  room,
  inspectionId,
  photos,
  onUploadProgress,
  note,
  roomFeedItemId,
  photosToDelete,
  rotatedPhotos,
}: {
  roomFeedItemId: string;
  room: Pick<Room, 'id' | 'jobId'>;
  inspectionId: string;
  photos: UploadPhoto[]; // base64 encoded from react-webcam
  onUploadProgress: (e: ProgressEvent) => void;
  note?: Partial<RoomNote>;
  photosToDelete?: Deletable[];
  rotatedPhotos?: RotatedPhotoDetailsInterface[];
}) {
  console.log('rotatedPhotos', rotatedPhotos);

  const formData = new FormData();
  photos.forEach((photo: UploadPhoto) => {
    if (!photo.isDefault) {
      formData.append(`photos`, base64ImageToBlob(photo.gcsPath));
      if (photo.id) {
        formData.append(`photoIds`, photo.id);
      }
    }
  });

  formData.append('photosToDelete', JSON.stringify(photosToDelete));
  formData.append('note', JSON.stringify(note));
  if (rotatedPhotos) {
    // remove photos that are to be deleted
    if (photosToDelete) {
      rotatedPhotos = rotatedPhotos.filter((rotatedPhoto) => {
        return !photosToDelete.some(
          (photoToDelete) => photoToDelete.id === rotatedPhoto.id
        );
      });
    }
    formData.append('rotatedPhotoDetails', JSON.stringify(rotatedPhotos));
  }
  const config = {
    onUploadProgress,
    headers: { 'content-type': 'multipart/form-data' },
  };

  const res = await Axios.put(
    `/api/v1/rooms/${room.jobId}/${room.id}/${inspectionId}/${roomFeedItemId}/photos`,
    formData,
    config
  );

  return res.data;
}

export async function uploadInventoryNote({
  id,
  room,
  inspectionId,
  photos,
  onUploadProgress,
  inventoryNote,
}: {
  id?: string;
  room: Pick<Room, 'id' | 'jobId'>;
  inspectionId: string;
  photos: UploadPhoto[]; // base64 encoded from react-webcam
  onUploadProgress: (e: ProgressEvent) => void;
  inventoryNote: Partial<InventoryItem>;
}) {
  const formData = new FormData();
  formData.append('roomFeedItemId', id ?? '');
  photos.forEach((photo: UploadPhoto) => {
    formData.append('photos', base64ImageToBlob(photo.gcsPath));
    if (photo.id) {
      formData.append(`photoIds`, photo.id);
    }
  });
  Object.keys(inventoryNote).forEach((k) => {
    formData.append(k, inventoryNote[k]);
  });
  const { disposition } = inventoryNote;
  if (!disposition) {
    return { success: false, message: 'Disposition not set!' };
  }

  const res = await Axios.post(
    `/api/v1/inventory/${room.jobId}/${room.id}/${inspectionId}`,
    formData,
    { onUploadProgress }
  );
  let { success } = res.data;
  return success;
}

export async function updateInventoryNote({
  room,
  inspectionId,
  photos,
  onUploadProgress,
  inventoryNote,
  roomFeedItemId,
  photosToDelete,
  rotatedPhotos,
}: {
  roomFeedItemId: string;
  room: Pick<Room, 'id' | 'jobId'>;
  inspectionId: string;
  photos: UploadPhoto[]; // base64 encoded from react-webcam
  onUploadProgress: (e: ProgressEvent) => void;
  inventoryNote?: Partial<InventoryItem>;
  photosToDelete?: Deletable[];
  rotatedPhotos?: RotatedPhotoDetailsInterface[];
}) {
  const formData = new FormData();
  photos.forEach((photo: UploadPhoto) => {
    if (!photo.isDefault) {
      formData.append('photos', base64ImageToBlob(photo.gcsPath));
      if (photo.id) {
        formData.append(`photoIds`, photo.id);
      }
    }
  });

  formData.append('photosToDelete', JSON.stringify(photosToDelete));

  if (rotatedPhotos) {
    // remove photos that are to be deleted
    if (photosToDelete) {
      rotatedPhotos = rotatedPhotos.filter((rotatedPhoto) => {
        return !photosToDelete.some(
          (photoToDelete) => photoToDelete.id === rotatedPhoto.id
        );
      });
    }
    formData.append('rotatedPhotoDetails', JSON.stringify(rotatedPhotos));
  }

  if (inventoryNote) {
    formData.append('inventoryItemUpdate', JSON.stringify(inventoryNote));
  }

  const config = {
    onUploadProgress,
    headers: { 'content-type': 'multipart/form-data' },
  };

  const res = await Axios.put(
    `/api/v1/inventory/${room.jobId}/${room.id}/${inspectionId}/${roomFeedItemId}/${inventoryNote.id}`,
    formData,
    config
  );

  let { success } = res.data;

  return { success };
}

export async function getMoistureReadings(
  jobId: string,
  roomId: string,
  inspectionId: string
): Promise<any[]> {
  if (
    jobId !== undefined &&
    roomId !== undefined &&
    inspectionId !== undefined
  ) {
    const res = await Axios.get(
      `/api/v1/moisture/${jobId}/${roomId}/${inspectionId}`
    );
    return res.data;
  }
}

export interface FloorPlanPhotoDeletable {
  id: string;
  path: string;
  isDefault?: boolean;
}

export async function uploadFloorPlanPhoto({
  inspectionId,
  jobId,
  floorPlanPhoto,
  photosToDelete,
  rotatedPhotos,
}: {
  inspectionId: string;
  jobId: string;
  floorPlanPhoto: UploadPhoto[]; // base64 encoded
  photosToDelete?: FloorPlanPhotoDeletable[]; // gcsPaths
  rotatedPhotos?: RotatedPhotoDetailsInterface[];
}) {
  const formData = new FormData();
  floorPlanPhoto.forEach((photo: UploadPhoto) => {
    if (!photo.id) {
      formData.append('photos', base64ImageToBlob(photo.gcsPath));
    }
  });

  formData.append('photosToDelete', JSON.stringify(photosToDelete));
  if (rotatedPhotos) {
    // remove photos that are to be deleted
    if (photosToDelete) {
      rotatedPhotos = rotatedPhotos.filter((rotatedPhoto) => {
        return !photosToDelete.some(
          (photoToDelete) => photoToDelete.id === rotatedPhoto.id
        );
      });
    }
    formData.append('rotatedPhotoDetails', JSON.stringify(rotatedPhotos));
  }

  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };

  const res = await Axios.post(
    `/api/v1/inspections/${jobId}/${inspectionId}/floor-plan`,
    formData,
    config
  );

  return res.data;
}

export interface FloorPlanPhoto {
  id: string;
  organizationId: number;
  jobId: string;
  inspectionId: string;
  createdOn: string;
  createdByUserId: string;
  updatedOn: string;
  updatedByUserId: string;
  gcsPath: string;
  isDefault?: boolean;
  rotateValue?: number;
}

export async function getFloorPlanPhotos({
  jobId,
  inspectionId,
}: {
  inspectionId: string;
  jobId: string;
}) {
  const res = await Axios.get(
    `/api/v1/inspections/${jobId}/${inspectionId}/floor-plan`
  );

  return res.data as FloorPlanPhoto[];
}

export async function updateSiteVisitDate({
  jobId,
  inspectionId,
  inspectionDate,
}: {
  jobId: string;
  inspectionId: string;
  inspectionDate: string;
}) {
  const res = await Axios.put(
    `/api/v1/inspections/${jobId}/${inspectionId}/site-visit-date`,
    { inspectionDate }
  );
  return res.data;
}

import { OrganizationPricingPlan } from 'api/organizationApi';
import { User } from 'api/userApi';

export type Role =
  | 'Administrator'
  | 'ProjectManager'
  | 'Supervisor'
  | 'Technician';

export function userHasAccess(user: User, requiredRoles: Role[]) {
  return requiredRoles.includes(user.role);
}

export function orgHasAccess(
  orgUser: User,
  allowedPlans: OrganizationPricingPlan[]
) {
  return allowedPlans.includes(orgUser.__organization__.pricingPlan);
}
